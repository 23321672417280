import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  mobile:'',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, mobile, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, mobile, email, message)
    emailjs
      .sendForm(
        'service_n4cgrth', 'template_i7v64do', e.target, 'XJYxfm5FtSm0Iv5zC'
      )
      .then(
        (result) => {
          alert('Your Request Has Been Submitted')
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-5'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                
                 
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='FullName*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  
                 
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>

                    <div className='form-group'>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Contact Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  
               
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-6 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Reach Us : 3rd floor, Van Heusen Showroom Building, West Boring Canal road, Patna BIHAR-800001</h3>
              <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.576727358212!2d85.11975431420737!3d25.618977720747658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed583729e13263%3A0xce1927764aaf412a!2sVanHeusen%20Store!5e0!3m2!1sen!2sin!4v1656061687241!5m2!1sen!2sin" width="100%" height="50%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      
    </div>
  )
}
