import React from 'react';

const CommonTab = (props) => {
  return (
    <>
    <div className="row">
        
    <div className="col-xs-12 col-md-6">
      <div className="workingtab-text">
      <h2>{props.header}</h2>
      <h3>{props.subheader}</h3>
      <p>
      <ul>{props.ul1}</ul>
      <ul>{props.ul2}</ul>
      <ul>{props.ul3}</ul>
      </p>
      </div>
   </div>
   <div className="col-xs-12 col-md-6" >
      {" "}
      <img src={props.imgsrc} className="img-responsive" alt="" />{" "}
    </div>
    </div> 

    </>
  );
}

export default CommonTab;