import React, { useState } from 'react'
var d = new Date();
var currYear = d.getFullYear();

const Footer3 = (props) => {
  return (
    <>
      <div id='footerdetail'>
        <div id='callus'>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <h2>STAY CONNECTED WITH US</h2>
                <ul className="about-social">
                  <li className="wow fadeIn" data-wow-delay=".2s">
                    <a
                      href="https://www.facebook.com/wappotix"
                      target="newtab"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="wow fadeIn" data-wow-delay=".4s">
                    <a
                      href="https://www.instagram.com/wappotix.social/"
                      target="newtab"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="wow fadeIn" data-wow-delay=".6s">
                    <a href="https://www.linkedin.com/in/wappotix-pvt-ltd-573452244/" target="newtab">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="wow fadeIn" data-wow-delay=".8s">
                    <a href="https://twitter.com/wappotixcares" target="newtab">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="wow fadeIn" data-wow-delay="1s">
                    <a href="https://pin.it/1nhrILc" target="newtab">
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="wow fadeIn" data-wow-delay="1.2s">
                    <a href="https://www.youtube.com/channel/UC8MaCf_WipqkETxt2bmnlYA" target="newtab">
                      <i className="fa fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 contact-info">
                <div className='contact-item'>
                  <h3>Contact Info</h3>
                  {/*
                                <p>
                                    <span>
                                        <i className='fa fa-map-marker'></i> Address:
                                    </span>
                                    {props.data ? props.data.address : 'loading'}
                                </p>
                              */}
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-phone'></i> Phone:
                    </span>{' '}
                    <a href="tel: 9031405444">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-envelope-o'></i> Email:
                    </span>{' '}
                    <a href="mailto: wappotix@gmail.com">
                      {props.data ? props.data.email : 'loading'}</a>
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-whatsapp'></i> Whatsapp:
                    </span>{' '}
                    <a href="https://api.whatsapp.com/send?phone=919031405444" target="_blank">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>

              </div>


              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ul className='contact-item'>
                  <h3>UseFul Links</h3>
                  <li>
                    <a href='#features' className='page-scroll'>
                      What We Do
                    </a>
                  </li>
                  <li>
                    <a href='#about' className='page-scroll'>
                      About
                    </a>
                  </li>
                  <li>
                    <a href='#services' className='page-scroll'>
                      Services
                    </a>
                  </li>
                  <li>
                    <a href='#workingtab' className='page-scroll'>
                      Expertize
                    </a>
                  </li>
                  <li>
                    <a href='#contact' className='page-scroll'>
                      Contact
                    </a>
                  </li>
                </ul>

              </div>
            </div>

            <div className='container text-left'>
              <p>
                Copyright &copy; {currYear}. All rights reserved. Design by{' '}
                <a href='#page-top' rel='nofollow'>
                  wAppotix
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer3;
